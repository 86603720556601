import { ComponentProps, FormEvent, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton, SecondaryButton } from 'shared/components/button'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import Modal from 'shared/components/modal'
import SenderEmailSelect from 'shared/components/sender-email-select'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useSenderEmailAddressActions } from '../hooks/use-sender-email-actions'
import { SenderEmailAddressInterface } from '../types/sender-email-address-interface'

interface AlterSenderEmailAddressModalProps
  extends Pick<ComponentProps<typeof Modal>, 'opened' | 'onClose' | 'afterLeave' | 'isFetching'> {
  email: SenderEmailAddressInterface
  onSave?: () => Promise<void>
}

interface AlterEmailFormInterface {
  replacementEmail: string
}

export default function AlterSenderEmailAddressModal(props: AlterSenderEmailAddressModalProps) {
  const { t } = useLocoTranslation()

  const [error, setError] = useState('')

  const [senderAddress, setSenderAddress] = useState<string>(props.email.email)

  const { replaceEmail, isFetching: isReplaceEmailFetching } = useSenderEmailAddressActions()

  const canApply = senderAddress && senderAddress !== props.email.email

  const reset = () => {
    setSenderAddress('')
    setError('')
    props.afterLeave?.()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setError('')

    try {
      const emailId = props.email.id
      const replaced = await replaceEmail(emailId, senderAddress)
      !!replaced && toast.success(t('global.changes_saved'))
      await props.onSave?.()
      props.onClose()
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof UnprocessableContentError) {
        // do nothing while we're migrating 400 errors
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const isFetching = isReplaceEmailFetching || props.isFetching

  return (
    <Modal
      title={t('dashboard.settings.mailing.sender_email.alter_modal.title')}
      opened={props.opened}
      onClose={props.onClose}
      afterLeave={reset}
      isFetching={isFetching}
    >
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-5">
        <p className="col-span-2">
          {t('dashboard.settings.mailing.sender_email.alter_modal.description')}
        </p>

        <SenderEmailSelect
          wrapperClassName="col-span-2"
          onChange={setSenderAddress}
          value={senderAddress}
          noHint
        />

        <FieldErrorAndDescription
          error={error}
          errorClassName={'text-center'}
          className={'col-span-2'}
        />

        <PrimaryButton type="submit" disabled={!canApply} isFetching={isFetching}>
          {t('global.apply')}
        </PrimaryButton>
        <SecondaryButton type="button" disabled={isFetching} onClick={props.onClose}>
          {t('global.cancel')}
        </SecondaryButton>
      </form>
    </Modal>
  )
}
